<template>
  <div class="box-container">
    <v-row>
      <v-col>
        <div class="d-flex align-center mb-5">
          <v-avatar
            tile
            size="25"
            class="me-3"
          >
            <img
              src="@/assets/images/icons/food_primary.svg"
              alt=""
            >
          </v-avatar>
          <h2 class="mb-0">
            {{ $t("Gestion des transporteurs") }}
          </h2>
        </div>
      </v-col>
      <v-col cols="auto">
        <v-row justify-lg="end">
          <v-col
            cols="12"
            md="6"
            lg="6"
          >
            <v-text-field
              v-model="filter.search"
              outlined
              dense
              hide-details
              :label="$t('Recherche...')"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
          >
            <v-btn
              color="primary"
              @click="newTransporter"
            >
              {{ $t("Ajouter un nouveau") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="d-none px-4 d-md-flex mb-5">
      <h4 class="font-600 grey--text text--darken-2 flex-1 mr-3">
        {{ $t("Nom") }}
      </h4>
      <h4 class="font-600 grey--text text--darken-2 flex-1 mr-3">
        {{ $t("Point Relais") }}
      </h4>
      <!--      <h4 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-2 flex-1 mr-3">
        {{ $t("Quantité") }}
      </h4>
      <h4 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-2 flex-1 mr-3">
        {{ $t("Catégorie") }}
      </h4>
      <h4 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-2 flex-1 mr-3">
        {{ $t("Status") }}
      </h4>-->
      <div class="px-5" />
      <div class="px-5" />
    </div>

    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      hide-default-footer
      :loading="loading"
      :loading-text="$t('Chargement...')"
    >
      <template #item="{ item }">
        <v-row>
          <v-col
            cols="12"
          >
            <!--            <a
              :href="`/create-product/${item.id}`"
              class="text-decoration-none"
            >-->
            <base-card class="">
              <div class="pa-4 d-flex align-center flex-wrap">
                <div class="flex-1 mr-3">
                  <h4 class="font-600 mb-0 text-14">
                    {{ item.name }}
                  </h4>

                </div>
                <div class=" mr-3">
                  <v-chip outlined small :color="item.has_relays == 1?'success':'warning'">{{ item.has_relays == 1? $t('Oui'): $t('Non') }}</v-chip>
                </div>
                <div class="d-flex">

                  <v-chip outlined small :color="item.status == 1?'success':'warning'" class="mx-1">{{ item.status == 1? $t('Actif'): $t('Inactif') }}</v-chip>
                  <v-btn
                    small
                    color="success"
                    class="mb-1 mx-1"
                    @click="editTransporter(item)"
                  >
                    <!--                      <v-icon>mdi-edit</v-icon>-->
                    {{ $t("Modifier") }}
                  </v-btn>
                  <br>
                  <v-btn

                    small
                    color="error"
                    class="mx-1"
                    @click.prevent="confirmProduct(item)"
                  >
                    {{ $t("Supprimer") }}
                  </v-btn>
                </div>
              </div>
            </base-card>
            <!--            </a>-->
          </v-col>
        </v-row>
      </template>
      <!--      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
          >
            <a
              href="#"
              class="text-decoration-none"
            >
              <base-card class="">
                <div class="pa-4 d-flex align-center flex-wrap">
                  <div class="flex-1 mr-3">
                    <h4 class="font-600 mb-0 ">1050017AS</h4>
                  </div>
                  <div class="flex-1 mr-3">
                    <v-chip
                      class="ma-2"
                      color="grey lighten-2"
                      small
                    >
                      Primary
                    </v-chip>
                  </div>
                  <div class="flex-1 mr-3">
                    <p class="mb-0">jun 09, 2021</p>
                  </div>

                  <div class="flex-1 mr-3">
                    <p class="mb-0">$350.00</p>
                  </div>
                  <v-btn
                    icon
                    color="grey"
                    class=""
                    to="/order-details"
                  >
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </div>
              </base-card>
            </a>
          </v-col>
        </v-row>
      </template>-->

      <template v-slot:footer>
        <v-row
          class="my-5 me-1"
          align="center"
          justify="center"
        >
          <v-spacer />

          <span
            class="mr-4
                                grey--text"
          >
            {{ $t("Page") }} {{ page }} {{ $t("de") }} {{ numberOfPages }}
          </span>
          <v-btn
            :loading="loading"
            fab
            :disabled="page == 1"
            small
            color="primary"
            class="mr-1"

            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            :loading="loading"
            fab

            :disabled="page == numberOfPages"
            small
            color="primary"
            class="ml-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
    <v-dialog
      v-model="confirm_dialog"
      width="400"
    >
      <v-card v-if="item.id">
        <v-card-title>
          {{ $t("Est vous sur de vouloir supprimer?") }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            small
            outlined
            depressed
            @click.stop="confirm_dialog = false"
          >
            {{ $t("Fermer") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            depressed
            :loading="loadingDelete"
            @click.stop="deleteProduct"
          >
            {{ $t("Continue") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="transporter_dialog"

      persistent
    >
      <v-card
        v-if="item"
        height="600"
      >
        <v-toolbar
          flat
        >
          <v-toolbar-title> {{ $t("Formulaire de transporteur") }}</v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="transporter_dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <template v-slot:extension>
            <v-tabs
              v-model="tab"
              align-with-title
            >
              <v-tabs-slider />

              <v-tab
                key="info"
              >
                {{ $t("Info") }}
              </v-tab>
              <v-tab
                v-if="item && item.id"
                key="tariffs"
              >
                {{ $t("Tariffs") }}
              </v-tab>
              <v-tab
                v-if="item && item.id"
                key="points"
              >
                {{ $t("Points Relais") }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item
            key="info"
          >
            <v-card flat>
              <v-card-text>
                <v-text-field
                  v-model="item.name"
                  :label="$t('Nom')"
                />
                <v-textarea
                  v-model="item.description"
                  :label="$t('Description')"
                />
                <v-switch v-model="item.status" :label="item.status ==1 ?$t('Actif'):$t('Inactif')" :false-value="0" :true-value="1" />
                <v-switch v-model="item.has_relays" :label="item.has_relays ==1 ?$t('Exige un point de relais de la part de l\'acheteur'):$t('N\'exige pas de l\'acheteur un point de relais')" :false-value="0" :true-value="1" />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
            key="tariffs"
          >
            <v-card flat>
              <v-card-text>
                <v-text-field
                  v-model="searchTariff"
                  append-icon="mdi-magnify"
                  outlined
                  dense
                  :label="$t('Rechercher')"
                />
                <v-data-table
                  :search="searchTariff"
                  :items="item.tariffs || []"
                  height="350"
                  :headers="tariffsHeader"
                  multi-sort
                  fixed-header
                >
                  <template #header>
                    <tariff-form
                      v-model="tariff"
                      is-new
                      :item="item"
                      @saved="onTariff"
                    />
                  </template>
                  <template #item="{ item }">
                    <tariff-form
                      v-model="item"
                      :item="transporter"
                      @saved="onTariff"
                      @deleted="onTariffDelete"
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
            key="points"
          >
            <v-card flat>
              <v-card-text>
                <v-text-field
                  v-model="searchPoint"
                  append-icon="mdi-magnify"
                  outlined
                  dense
                  :label="$t('Rechercher')"
                />
                <v-data-table
                  :search="searchPoint"
                  :items="item.points || []"
                  height="350"
                  :headers="pointsHeader"
                  multi-sort
                  fixed-header
                >
                  <template #header>
                    <points-form
                    v-model="point"
                    :transporter="item"
                    is-new
                    @saved="onPoint"
                    @deleted="onPointDelete"
                  />
                  </template>
                  <template #item="{ item }">
                    <points-form
                      v-model="item"
                      :transporter="transporter"
                      @saved="onPoint"
                      @deleted="onPointDelete"
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-btn
            small
            outlined
            depressed
            @click.stop="transporter_dialog = false"
          >
            {{ $t("Fermer") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            depressed
            :loading="loading"
            @click.stop="save"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>
<script>

  import * as url from 'url'
  import Vue from 'vue'
  import { mapState } from 'vuex'
  import i18n from '../../i18n'
  import PointsForm from '../../components/shops/PointsForm.vue'
  import TariffForm from '../../components/shops/TariffForm.vue'

  export default {
    components: {
      TariffForm,
      PointsForm,

    },
    props: {
    },
    data () {
      return {
        tab: false,
        isSidebar: false,
        dateMenu: false,
        savingTariff: false,
        activePicker: null,
        transporter_dialog: false,
        filter: {
          status: null,
          search: '',
          date: null, /* [new Date(Date.now()).toISOString().substr(0, 10), new Date(Date.now()).toISOString().substr(0, 10)] */
        },
        page: 0,
        itemsPerPage: 5,
        pagination: { itemsPerPage: 5, page: 1 },
        item: { tariffs: [], points: [] },
        items: [],
        total: 0,
        loading: false,
        timeout: null,
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        search: '',
        searchTariff: '',
        searchPoint: '',
        url: '',
        confirm_dialog: false,
        loadingDelete: false,
        snackbar: { active: false, text: '', color: 'info' },

        step: 1,
        countries: [],
        selected: [],
        tariff: {
          country_id: null,
          min_weight: null,
          max_weight: null,
          weight_price: null,
          weight_price_ttc: null,
        },
        point: {
          Pays: null,
          Ville: null,
          CP: null,
          LgAdr1: null,
        },
        errors: {
          name: false,
          introduction: false,
          description: false,
          price: false,
          quantity: false,
        },
        tariffsHeader: [],
        pointsHeader: [
          { text: i18n.t('Pays'), value: 'Pays' },
          { text: i18n.t('Ville'), value: 'Ville' },
          { text: i18n.t('CP'), value: 'CP' },
          { text: i18n.t('Adresse'), value: 'LgAdr1' },
          { text: i18n.t('Actions') },
        ],
      }
    },
    computed: {
      ...mapState({
        user: 'user',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),
      transporter () {
        return this.item || {}
      },
      numberOfPages () {
        return Math.ceil(this.total / this.itemsPerPage)
      },
      params (nv) {
        return {
          ...this.filter,
        }
      },
      selectedCountries () {
        var ctries = []
        for (var i = 0; i < this.countries.length; i++) {
          if (this.selected.indexOf(this.countries[i].id) > -1) {
            ctries.push(this.countries[i])
          }
        }
        return ctries
      },
      filteredTariffs () {
        return this.item.tariffs || []
        /* if (this.item) {
          this.item.tariffs = this.item.tariffs || []
          return this.item.tariffs.filter(item => item.country_code.includes(this.searchTariff.toUpperCase()) ||
            item.country_name.toUpperCase().includes(this.searchTariff.toUpperCase()))
        }
        return [] */
      },
      filteredPoints () {
        return this.item.points || []
        /* if (this.item) {
          this.item.tariffs = this.item.tariffs || []
          return this.item.tariffs.filter(item => item.country_code.includes(this.searchTariff.toUpperCase()) ||
            item.country_name.toUpperCase().includes(this.searchTariff.toUpperCase()))
        }
        return [] */
      },
    },
    watch: {
      params: {
        handler () {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.items = []
            this.total = 0
            this.page = 0
            this.getData().then(data => {
              this.items = data.items
              this.total = data.total
              if (this.page + 1 <= this.numberOfPages) this.page += 1
            })
          }, 1500)
        },
        deep: true,
      },
    },
    mounted () {
      this.url = this.$route.path
      this.getData().then(data => {
        this.items = data.items
        this.total = data.total
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      })
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
      /* if (this.item.tariffs.length > 0) {

        this.tariff.country_code = this.item.tariffs[this.item.tariffs.length - 1].country_code || null;
      } */
      this.tariffsHeader = [
        { text: i18n.t('Pays'), value: 'country_name' },
        { text: i18n.t('Poids min'), value: 'min_weight' },
        { text: i18n.t('Poids maximum'), value: 'max_weight' },
      ]
      if(this.user.is_pro_seller){
        this.tariffsHeader.push({ text: i18n.t('Prix HT'), value: 'weight_price' });
        this.tariffsHeader.push({ text: i18n.t('Prix TTC'), value: 'weight_price_ttc' });
        this.tariffsHeader.push({ text: i18n.t('Actions') },);
      }else{
        this.tariffsHeader.push({ text: i18n.t('Prix TTC'), value: 'weight_price_ttc' });
        this.tariffsHeader.push({ text: i18n.t('Actions') },);

      }

    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      onPoint (point) {
        this.item.points = this.item.points || []
        const index = this.item.points.findIndex((e) => e.id === point.id)
        if (index >= 0) {
          Vue.set(this.item.points, index, point)
        } else {
          this.item.points.unshift(point)
        }
      },
      onPointDelete (point) {
        this.item.points = this.item.points || []
        const index = this.item.points.findIndex((e) => e.id === point.id)
        this.item.points.splice(index, 1)
      },
      onTariff (tariff) {
        this.item.tariffs = this.item.tariffs || []
        const index = this.item.tariffs.findIndex((e) => e.id === tariff.id)
        if (index >= 0) {
          Vue.set(this.item.tariffs, index, tariff)
        } else {
          this.item.tariffs.unshift(tariff)
        }
      },
      onTariffDelete (tariff) {
        this.item.tariffs = this.item.tariffs || []
        const index = this.item.tariffs.findIndex((e) => e.id === tariff.id)
        this.item.tariffs.splice(index, 1)
      },
      newTransporter () {
        this.item = {}
        this.transporter_dialog = true
      },
      editTransporter (item) {
        this.item = item
        this.transporter_dialog = true
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) {
          this.getData().then(data => {
            for (var i = 0; i < data.items.length; i++) {
              var index = this.items.findIndex(e => e.id === data.items[i].id)
              if (index === -1) {
                this.items.push(data.items[i])
              } else {
                this.items[index] = data.items[i]
              }
            }
            // this.items.push(...data.items)

            this.total = data.total
            if (this.page + 1 <= this.numberOfPages) this.page += 1
          })
        }
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },
      getData () {
        this.loading = true
        return new Promise((resolve, reject) => {
          console.log(this.pagination)
          var _this = this
          /* if(this.pagination.sortBy)
                               for(var i=0;i<this.pagination.sortBy.length;i++){

                                   orderBy.push({
                                       column:this.pagination.sortBy[i],
                                       direction:this.pagination.sortDesc[i]?"DESC":"ASC"
                                   });
                               } */

          // get by search keyword
          // eslint-disable-next-line no-undef
          axios.get(this.url, {
            params: {
              term: this.filter.search,
              date: this.filter.date,
              status: this.filter.status,
              orderBy: this.pagination.sortBy ? this.pagination.sortBy[0] : 'id',
              direction: (this.pagination.sortDesc) ? (this.pagination.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
              page: this.page + 1,
              per_page: this.pagination.itemsPerPage,
            },
          })
            .then(res => {
              const items = res.data.data
              const total = res.data.total
              this.loading = false
              resolve({
                items,
                total,
              })
            })
            .catch(err => console.log(err))
            // eslint-disable-next-line no-return-assign
            .finally(() => _this.loading = false)
        })
      },
      confirmProduct (item) {
        this.item = item
        this.confirm_dialog = true
      },
      deleteProduct () {
        this.loadingDelete = true
        axios.delete(`/transporters/${this.item.id}`).then((response) => {
          if (response.data && response.data.error) {
            this.snackbar.text = response.data.message || 'Erreur'
            this.snackbar.active = true
          } else {
            this.items.splice(this.items.findIndex(e => e.id === this.item.id), 1)
          }
        }).then(() => {
          this.loadingDelete = false
          this.confirm_dialog = false
        })
      },
      save () {
        this.loading = true
        axios.post('/transporters', this.item).then((response) => {
          if (response.data && response.data.error) {
            this.snackbar.text = response.data.message || 'Erreur'
            this.snackbar.active = true
          } else {
            if (this.item.id) {
              Vue.set(this.items, this.items.findIndex((e) => e.id === this.item.id), this.item)
            } else {
              this.item = response.data
              this.items.push(this.item)
            }
          }
        }).then(() => {
          this.loading = false
          this.transporter_dialog = false
        })
      },
      saveCountry () {
        if (!this.selected.length) {
          this.snackbar.text = this.$t('Select a country of operation')
          this.snackbar.active = true
          return false
        }

        /* if (!this.transporter.description || this.transporter.description.trim() == "") {
             this.errors.description = true;
             error = true;
         }
*/

        // eslint-disable-next-line no-undef
        axios.post('/transporter/countries/' + this.item.id, { ids: this.selected })
          .then((response) => {
            // this.step = 3;
            this.loading = false
          }).catch(error => {
            this.loading = false
            console.log(error)
          })
      },
    },
  }
</script>
