<template>
  <tr>
    <td :class="{'pl-2 pr-2 pt-2': isNew }">
      <v-autocomplete
        v-model="tariff.country_code"
        :label="$t('Pays')"
        :placeholder="$t('Pays')"
        class="form-control"
        :items="countries"
        item-value="code"
        item-text="name"
        dense
        :hide-details="!isNew"
        outlined
      />
    </td>
    <td :class="{'pl-2 pr-2 pt-2': isNew }">
      <v-text-field
        v-model="tariff.min_weight"

        outlined
        dense
        :hide-details="!isNew"
        :label="$t('Poids min')"
        :placeholder="$t('Poids min')"
        type="number"
      />
    </td>
    <td :class="{'pl-2 pr-2 pt-2': isNew }">
      <v-text-field
        v-model="tariff.max_weight"

        outlined
        dense
        :hide-details="!isNew"
        :label="$t('Poids maximum')"
        :placeholder="$t('Poids maximum')"
        type="number"
      />
    </td>
    <td v-if="user.is_pro_seller" :class="{'pl-2 pr-2 pt-2': isNew }">
      <v-text-field
        v-model="tariff.weight_price"

        outlined
        dense
        :hide-details="!isNew"
        :label="$t('Prix HT')"
        :placeholder="$t('Prix HT')"
        type="number"
      />
    </td>
    <td :class="{'pl-2 pr-2 pt-2': isNew }">
      <v-text-field
        v-model="tariff.weight_price_ttc"

        outlined
        dense
        :hide-details="!isNew"
        :label="$t('Prix TTC')"
        :placeholder="$t('Prix TTC')"
        type="number"
      />
    </td>
    <td :class="{'pl-2 pr-4': isNew }">
      <v-btn
        small
        depressed
        color="success"
        :loading="loading"
        @click="save"
      >
        {{ isNew ?$t("Enregistrer"): $t("Mettre à jour") }}
      </v-btn>
      <v-dialog
        v-if="!isNew"
        v-model="confirm_dialog"
        width="400"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            class="ml-2 mt-2"
            outlined
            small
            depressed
            color="danger"
            :loading="loadingDelete"
            v-on="on"
          >
            {{ $t("Supprimer") }}
          </v-btn>
        </template>
        <v-card v-if="item.id">
          <v-card-title>
            {{ $t("Est vous sur de vouloir supprimer?") }}
          </v-card-title>
          <v-card-actions>
            <v-btn
              small
              outlined
              depressed
              @click.stop="confirm_dialog = false"
            >
              {{ $t("Fermer") }}
            </v-btn>
            <v-spacer />
            <v-btn
              small
              color="primary"
              depressed
              :loading="loadingDelete"
              @click="deleteTariff"
            >
              {{ $t("Continue") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </td>
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
    </v-snackbar>
  </tr>
</template>
<script>

  import * as url from 'url'
  import Vue from 'vue'
  import { mapState } from 'vuex'

  export default {
    components: {

    },
    props: {
      value: { type: [Object, Array], default: () => {} },
      item: { type: [Object, Array], default: () => { return { tariffs: [] } } },
      isNew: { type: Boolean, default: false },

    },
    data () {
      return {
        tab: false,
        isSidebar: false,
        dateMenu: false,
        activePicker: null,
        transporter_dialog: false,
        filter: {
          status: null,
          search: '',
          date: null, /* [new Date(Date.now()).toISOString().substr(0, 10), new Date(Date.now()).toISOString().substr(0, 10)] */
        },
        page: 0,
        itemsPerPage: 5,
        pagination: { itemsPerPage: 5, page: 1 },

        items: [],
        total: 0,
        loading: false,
        timeout: null,
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        search: '',
        url: '',
        confirm_dialog: false,
        loadingDelete: false,
        snackbar: { active: false, text: '', color: 'info' },

        step: 1,
        query: '',
        selected: [],
        tariff: {
          country_id: null,
          min_weight: null,
          max_weight: null,
          weight_price: null,
          weight_price_ttc: null,
        },
        point: {
          Pays: null,
          Ville: null,
          CP: null,
          LgAdr1: null,
        },
        errors: {
          name: false,
          introduction: false,
          description: false,
          price: false,
          quantity: false,
        },
      }
    },
    computed: {
      ...mapState({
        user: 'user',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),
      numberOfPages () {
        return Math.ceil(this.total / this.itemsPerPage)
      },
      params (nv) {
        return {
          ...this.filter,
        }
      },
      selectedCountries () {
        var ctries = []
        for (var i = 0; i < this.countries.length; i++) {
          if (this.selected.indexOf(this.countries[i].id) > -1) {
            ctries.push(this.countries[i])
          }
        }
        return ctries
      },
      filteredTariffs () {
        return this.item.tariffs.filter(item => item.country_code.includes(this.query.toUpperCase()) ||
          item.country_name.toUpperCase().includes(this.query.toUpperCase()))
      },
    },
    watch: {
      params: {
        handler () {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.items = []
            this.total = 0
            this.page = 0
            this.getData().then(data => {
              this.items = data.items
              this.total = data.total
              if (this.page + 1 <= this.numberOfPages) this.page += 1
            })
          }, 1500)
        },
        deep: true,
      },
      value: {
        handler (val) {
          this.tariff = val
        },
        deep: true,
      },
      item: {
        handler (val) {
          console.log(val)
        },
        deep: true,
      },

    },
    mounted () {

    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
      this.tariff = this.value || {}
      /* if (this.item && this.item.tariffs.length > 0) {
        this.tariff.country_code = this.item.tariffs[this.item.tariffs.length - 1].country_code || null
      } */
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      newTransporter () {
        this.item = {}
        this.transporter_dialog = true
      },
      editTransporter (item) {
        this.item = item
        this.transporter_dialog = true
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) {
          this.getData().then(data => {
            for (var i = 0; i < data.items.length; i++) {
              var index = this.items.findIndex(e => e.id === data.items[i].id)
              if (index === -1) {
                this.items.push(data.items[i])
              } else {
                this.items[index] = data.items[i]
              }
            }
            // this.items.push(...data.items)

            this.total = data.total
            if (this.page + 1 <= this.numberOfPages) this.page += 1
          })
        }
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },
      getData () {
        this.loading = true
        return new Promise((resolve, reject) => {
          console.log(this.pagination)
          var _this = this
          /* if(this.pagination.sortBy)
                               for(var i=0;i<this.pagination.sortBy.length;i++){

                                   orderBy.push({
                                       column:this.pagination.sortBy[i],
                                       direction:this.pagination.sortDesc[i]?"DESC":"ASC"
                                   });
                               } */

          // get by search keyword
          // eslint-disable-next-line no-undef
          axios.get(this.url, {
            params: {
              term: this.filter.search,
              date: this.filter.date,
              status: this.filter.status,
              orderBy: this.pagination.sortBy ? this.pagination.sortBy[0] : 'id',
              direction: (this.pagination.sortDesc) ? (this.pagination.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
              page: this.page + 1,
              per_page: this.pagination.itemsPerPage,
            },
          })
            .then(res => {
              const items = res.data.data
              const total = res.data.total
              this.loading = false
              resolve({
                items,
                total,
              })
            })
            .catch(err => console.log(err))
            // eslint-disable-next-line no-return-assign
            .finally(() => _this.loading = false)
        })
      },
      confirmProduct (item) {
        this.item = item
        this.confirm_dialog = true
      },
      deleteProduct () {
        this.loadingDelete = true
        axios.delete(`/transporters/${this.item.id}`).then((response) => {
          if (response.data && response.data.error) {
            this.snackbar.text = response.data.message || 'Erreur'
            this.snackbar.active = true
          } else {
            this.items.splice(this.items.findIndex(e => e.id === this.item.id), 1)
          }
        }).then(() => {
          this.loadingDelete = false
          this.confirm_dialog = false
        })
      },
      toast (message, color) {
        this.snackbar.text = message
        this.snackbar.active = true
      },
      save () {
        if (!this.tariff.country_code) {
          this.toast(this.$t('Choisissez un pays'), 'red')
          return false
        }
        if (this.tariff.min_weight === undefined) {
          this.toast(this.$t('Entrez le poids minimum'), 'red')
          return false
        }
        if (!this.tariff.max_weight) {
          this.toast(this.$t('Entrez le poids maximum'), 'red')
          return false
        }
        if (!this.tariff.weight_price_ttc) {
          this.toast(this.$t('Entrez le prix'), 'red')
          return false
        }

        this.loading = true
        var _this = this
        axios.post('/transport/store_tariff/' + this.item.id, this.tariff)
          .then((response) => {
            _this.loading = false

            if (this.isNew) {
              this.tariff = {}
              this.$emit('saved', response.data)
            } else {
              this.$emit('input', response.data)
            }
            this.toast(this.$t('Tariff enregistré avec success'), 'success')
            return false
          }).catch(function (error) {
            console.log(error)
            _this.loading = false
          }).then(() => {
            this.loading = false
          })
      },
      deleteTariff () {
        this.loadingDelete = true
        var _this = this
        // eslint-disable-next-line no-undef
        axios.post('/transport/delete_tariff/' + this.tariff.id)
          .then((response) => {
            this.loadingDelete = false
            this.$emit('deleted', this.tariff)
          }).catch(function (error) {
            console.log(error)
            _this.loading = false
          })
      },
    },
  }
</script>
